.projetosPagged{
    padding: var(--double-default-spacing);
    text-align: justify;
}

.projetos-item{
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid grey;
    margin-bottom: var(--double-default-spacing);
    padding-bottom: var(--default-spacing);
}

.projetos-item-imagem{
    display: flex;
    justify-content: center;
}

.projetos-item-imagem img {
    width: 20rem;
    height: 20rem;
    margin-right: 1rem;
    border-radius: .5rem;
    cursor: pointer;
}
.projetos-item-descricao {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--default-spacing);
}

.projetosPagged-full{
    min-height: 80vh;
    text-align: justify;
    margin: auto;
    padding: var(--triple-default-spacing);
}

.apresentacao-projetos {
    padding: var(--triple-default-spacing);
}

.projetos-item-descricao h4{
    font-weight: normal;
    text-decoration: none;
    cursor: auto;
}

.projetos-item-descricao sub{
    display: flex;
    flex-direction: column;
    margin-bottom: var(--default-spacing);
}

.projetos-item-titulo{
    display: flex;
    flex-direction: column;
}

.projetos-item-titulo a{
    text-decoration: none;
    color: var(--text-color-primary);
}

@media screen and (min-width: 1000px){
    .projetos-item{
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid grey;
        width: 100%;
    }
    .projetos-item-imagem img {
        width: 10rem;
        height: 10rem;
        margin-right: 1rem;
        border-radius: .5rem;
    }
    .projetos-item-descricao {
        display: flex;
        flex-direction: column;
    }
    .projetos-item-titulo{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}