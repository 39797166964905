.projeto-full{
    margin: auto;
    padding: var(--triple-default-spacing);
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
}

.projeto-full sub{
    margin-bottom: var(--triple-default-spacing);
}

.projeto-img{
    display: flex;
    justify-content: center;
    align-items: center; /* Optional: if you want to center vertically as well */
    width: 100%; /* Ensure the container takes the full width */
    text-align: center; /* Center the contents textually, though flex should handle it */
}

.projeto-full img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    display: block; 
    margin: auto; 
}

.projeto-link {
    padding-top: var(--triple-default-spacing);
    word-break: break-all;
}


.projeto-link a{
    text-decoration: none;
    cursor: pointer;
    color: var(--text-color-primary);
}

.projeto-full h4, h3 {
    text-align: justify;
    font-weight: normal;
    text-decoration: none;
}
  
.projeto-full a {
    cursor: pointer;
    text-decoration: none; 
    font-weight: normal !important;
    color: inherit; 
}

.projeto-title-div{
    display: flex;
    width: 100%;
}

.projeto-download-icon {
    margin: 12px;
}

.projeto-title, .projeto-download-icon{
    cursor: pointer;
}

.enviarFeedBack{
    display: flex;
    margin-top: var(--default-spacing);
}

.enviarFeedBack a{
    border-radius: var(--default-spacing);
    color: var(--text-color-primary);
    text-decoration: double;
    cursor: pointer;
    border-bottom: 2px solid gray;
    border-top: 2px solid gray;
}

.enviarFeedBack button :hover
{
    background-color: var(--transient-color-second);
}

.curtidas{
    margin-top: var(--triple-default-spacing);
    display: flex;
    justify-content: right;
}

.curtidas div{
    margin: var(--default-spacing);
}